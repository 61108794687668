import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CollarTallas = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/collar-guia-tallas.png"
      alt="Collar Kolyy"
    />
  );
};

export default CollarTallas;
