import React from "react";
import "./booking.css";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

const ContactForm = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "8567670",
    formId: "5545aa46-077e-4cc1-9d71-c029ae47b5a0",
    target: "#booking-form",
  });

  return (
    <div id="form-container">
      <div id="booking-form"></div>
    </div>
  );
};

export default ContactForm;
