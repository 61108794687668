import React from "react";
import Layout from "../components/layout/layout";
import ColorChange from "../components/reserva/components/ColorChange";
import BookingForm from "../components/reserva/components/Form/BookingForm";
import CollarInfo from "../components/reserva/components/CollarInfo/CollarInfo";
import PlanKolyy from "../components/reserva/components/Plan/PlanKolyy";
import Seo from "../components/seo";
import Feedbacks from "../components/_Landing/Feedback/Feedbacks";
import ImgCollection from "../components/_Landing/ImgCollection/ImgCollection";
import FaQs from "../components/_Landing/About/components/FaQs";

const Reserva = () => {
  return (
    <Layout>
      <Seo title="Reserva" />
      <Seo title="Compra" />
      <ColorChange />
      <BookingForm />
      <CollarInfo />
      <PlanKolyy />
      <div style={{height:'12vw', maxHeight:'150px', backgroundColor:'white'}}></div>
      <Feedbacks />
      <div style={{height:'12vw', maxHeight:'150px', backgroundColor:'white'}}></div>
      <ImgCollection/>
      <FaQs/>
    </Layout>
  );
};

export default Reserva;
