import React from "react";
import { collectionOfSizes } from "../../../compra/components/Modals/collectionOfSizes";
import { info_container, img, flex, row } from "./sizeCards.module.scss";
import { Container, Row, Col } from "react-bootstrap";

const SizeCards = () => {
  return (
    <div>
      <h3 style={{color:'white'}}>Guía de tallas</h3>
      <Container>
        <Row className={row}>
          {collectionOfSizes.map((item) => {
            return (
              <Col xs={12} md={4}>
                <div
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-duration="1000"
                  className={info_container}
                >
                  <div className={img}>{item.img}</div>
                  <div className={flex}>
                    <p>{item.title}</p>
                    <p>{item.size}</p>
                  </div>
                  <p>{item.text}</p>
                  {item.numbSize && (
                    <>
                      <br />
                      <p>Diámetro del cuello:</p>
                      <p>
                        <b>{item.numbSize}</b>
                      </p>
                    </>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default SizeCards;
