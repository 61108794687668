import React, { useState } from "react";
import SubscriptionPlan from "../../../compra/components/Modals/SubscriptionPlan";
import Table from "./components/Table";
import { wrapper, container } from "./plan.module.scss";

const PlanKolyy = () => {
  const [openSubscription, setOpenSubscription] = useState(false);
  const openSubscriptionModal = () => {
    setOpenSubscription(!openSubscription);
  };
  return (
    <>
      {openSubscription && (
        <div>
          <SubscriptionPlan openModal={openSubscriptionModal} />
        </div>
      )}
      <div
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-duration="1000"
        className={wrapper}
      >
        <p>Puedes elegir el plan que mejor se adapte a tus necesidades.</p>
        <div className={container}>
          <Table />
        </div>
        <span onClick={openSubscriptionModal}>Más información</span>
      </div>
    </>
  );
};

export default PlanKolyy;
