import React from "react";
import SizeCards from "../SizeCards/SizeCards";
import Form from "./components/Form";
import Rounds from "./components/Rounds/Rounds";
import { container, content } from "./form.module.scss";

const BookingForm = () => {
  return (
    <div className={container}>
      <div className={content}>
        <SizeCards />
        <div
          id="form"
        >
          <Form />
        </div>
      </div>
      <Rounds />
    </div>
  );
};

export default BookingForm;
