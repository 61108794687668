import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ImgIntro = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../images/features_appl.png"
      alt="características del collar inteligente Kolyy"
    />
  );
};

export default ImgIntro;
