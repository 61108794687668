import React from "react";
import ArnesTallas from "./img/ArnesTallas";
import CollarTallas from "./img/CollarTallas";

export const collectionOfSizes = [
  {
    index: 1,
    img: <ArnesTallas/>,
    title: "Perros mini",
    size: "Arnés",
    arnesSize: "28 - 35 cm",
    text: "Bichón maltés, Yorkshire Terrier, Pomerania, Chihuahua...",
  },
  {
    index: 2,
    img: <CollarTallas />,
    title: "Perros pequeños:",
    size: "Talla S",
    numbSize: "28 - 35 cm",
    text: "Dachshund, Bulldog Francés, Boston Terrier, Mini Aussie...",
  },
  {
    index: 3,
    img: <CollarTallas />,
    title: "Perros medianos:",
    size: "Talla M",
    numbSize: "35 - 48 cm",
    text: "Aussie, Beagle, Border Collie, Corgi, Bulldog Inglés...",
  },
  {
    index: 3,
    img: <CollarTallas />,
    title: "Perros grandes:",
    size: "Talla L",
    numbSize: "45 - 65 cm",
    text: "Boxer, Doberman, German Shepherd, Golden Retriever, Pit Bull...",
  },
  {
    index: 4,
    img: <CollarTallas />,
    title: "Perros maxi:",
    size: "Talla XL",
    numbSize: "58 - 85 cm",
    text: "Mastín Español, Gran Danés, San Bernardo, Terranova, Rottweiler...",
  },
];
