import React from "react";
import { Link } from "react-scroll";
import {
  container,
  flex,
} from "./rounds.module.scss";
import lote_1 from "../../../../../../images/lote_1.png"
import lote_2 from "../../../../../../images/lote_2.png"
import lote_3 from "../../../../../../images/lote_3.png"


const Rounds = () => {
  return (
    <div className={container}>
      <p data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        ¡Reserva ahora para asegurarte
        <br />
        tu collar kolyy!
      </p>
      <div className={flex}>
        <section>
          <img src={lote_1} alt="" />
        </section>
        <section>
          <img src={lote_2} alt="" />
        </section>
        <Link to="form" spy={true} smooth={true} offset={-40} duration={1000}>
        <section>
          <img src={lote_3} alt="" />
        </section>
        </Link>
        {/* <section
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={round1}
        >
          <h2>LOTE 1</h2>
          <h2>EARLY - KOLYER</h2>
          <span>sold out</span>
          <MediaQuery maxWidth={870}>
            <img className={img} src={entrega} alt="entrega marzo" />
          </MediaQuery>
          <MediaQuery minWidth={870}>
            <img className={abs_img} src={desktopEntrega} alt="entrega marzo" />
          </MediaQuery>
        </section>

        <Link to="form" spy={true} smooth={true} offset={-40} duration={1000}>
          <section
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={round2}
          >
            <h2>LOTE 2</h2>
            <h2 style={{ color: "#30AAAA" }}>INNOVATORS</h2>
            <span>Reserva ahora</span>
            <img className={abs_img} src={entrega2} alt="entrega junio" />
          </section>
        </Link> */}
      </div>
    </div>
  );
};

export default Rounds;
