import React, { useState, useEffect } from "react";
import { container, img, text } from "../reserva.module.scss";
import MediaQuery from "react-responsive";
import ImgIntro from "./ImgIntro";
import BlackCollar from "../../_Landing/CollarInfo/components/ColorChange/components/img/BlackCollar";
import RedCollar from "../../_Landing/CollarInfo/components/ColorChange/components/img/RedCollar";
import BlueCollar from "../../_Landing/CollarInfo/components/ColorChange/components/img/BlueCollar";
import ColorSwapper from "../../_Landing/CollarInfo/components/ColorChange/components/ColorSwapper";

const ColorChange = () => {
  const [color, setColor] = useState("#1F1F1F");
  const [collar, setCollar] = useState(<BlackCollar />);

  const [bgColor, setBgColor] = useState(
    "radial-gradient(58.95% 46.94% at 50% 50%, #B8B6B6 0%, #818181 100%)"
  );

  const setBlack = () => {
    setBgColor(
      "radial-gradient(58.95% 46.94% at 50% 50%, #B8B6B6 0%, #818181 100%)"
    );
    setCollar(<BlackCollar />);
  };
  const setBlue = () => {
    setBgColor(
      "radial-gradient(58.95% 46.94% at 50% 50%, #9AD6D6 0%, #37B4B4 100%)"
    );
    setCollar(<BlueCollar />);
  };
  const setRed = () => {
    setBgColor(
      "radial-gradient(58.95% 46.94% at 50% 50%, #9F3F55 0%, #932E45 100%)"
    );
    setCollar(<RedCollar />);
  };
  useEffect(() => {
    switch (color) {
      case "#1F1F1F":
        return setBlack();
      case "#30AAAA":
        return setBlue();
      case "#812439":
        return setRed();
      default:
        break;
    }
  }, [color]);
  return (
    <div style={{ background: bgColor }} className={container}>
      <MediaQuery maxWidth={870}>
        <section
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={text}
        >
          <h2>
            Personaliza
            <br />
            tu collar
          </h2>
          <div style={{ margin: "0 auto", maxWidth: "250px" }}>
            <ImgIntro />
          </div>
          <section
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={img}
          >
            {collar}
          </section>
          <ColorSwapper color={color} setColor={setColor} />
        </section>
      </MediaQuery>
      <MediaQuery minWidth={870}>
        <section
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={img}
        >
          {collar}
        </section>
        <section
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={text}
        >
          <h2>
            Personaliza
            <br />
            tu collar
          </h2>
          <div style={{ margin: "0 auto", maxWidth: "250px" }}>
            <ImgIntro />
          </div>
          <br />
          <ColorSwapper color={color} setColor={setColor} />
        </section>
      </MediaQuery>
    </div>
  );
};

export default ColorChange;
