// extracted by mini-css-extract-plugin
export var abs = "introCard-module--abs--BXkRn";
export var btn_container = "introCard-module--btn_container--bzXdf";
export var container = "introCard-module--container--yXWQ7";
export var flex = "introCard-module--flex--iu+An";
export var img = "introCard-module--img--VkDJk";
export var info_container = "introCard-module--info_container--bhrJd";
export var interrogant = "introCard-module--interrogant--z9kAP";
export var only_kolyer_btn = "introCard-module--only_kolyer_btn--Dd6fe";
export var subscription_container = "introCard-module--subscription_container--SbLeB";
export var text = "introCard-module--text--Tdmk5";