import React, { useState } from "react";
import SubscriptionInfo from "../../../compra/components/IntroCard/components/SubscriptionInfo";
import SubscriptionPlan from "../../../compra/components/Modals/SubscriptionPlan";
import BlackCollar from "../../../_Landing/CollarInfo/components/ColorChange/components/img/BlackCollar";
import { container, img, text } from "./collar-info.module.scss";

const CollarInfo = () => {
  const [openSubscription, setOpenSubscription] = useState(false);
  const openSubscriptionModal = () => {
    setOpenSubscription(!openSubscription);
  };
  return (
    <div className={container}>
      {openSubscription && (
        <div>
          <SubscriptionPlan openModal={openSubscriptionModal} />
        </div>
      )}
      <section
        data-sal="fade"
        data-sal-delay="100"
        data-sal-duration="1000"
        className={img}
      >
        <BlackCollar />
      </section>
      <section className={text}>
        <SubscriptionInfo openSubscriptionModal={openSubscriptionModal} />
        <br />
        <br />
        <p data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        El collar kolyy se conecta a una App que personaliza y analiza toda la información que necesitas saber de tu perro.
        </p>
        <br />
        <p data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
          La App kolyy incluye:
        </p>
        <ul data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
          <li>Localizador GPS.</li>
          <li>LIVE Tracking ilimitado.</li>
          <li>Monitor de actividad en pasos y tiempo.</li>
          <li>Historial de semanas, meses y años.</li>
          <li>Diagnóstico mensual de su estado de forma.</li>
          <li>Registro de paseos.</li>
          <li>Contenido personalizado.</li>
        </ul>
      </section>
    </div>
  );
};

export default CollarInfo;
