import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ArnesTallas = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/arnes-guia-tallas.png"
      alt="Arnés Kolyy"
    />
  );
};

export default ArnesTallas;
