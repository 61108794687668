import React from "react";
import {
  subscription_container,
  flex,
  interrogant,
} from "../introCard.module.scss";
import SubscriptionModal from "./SubscriptionModal";

const SubscriptionInfo = ({ openSubscriptionModal }) => {
  return (
    <div data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
      <div className={subscription_container} onClick={openSubscriptionModal}>
        <h4>Kolyy, el collar inteligente para perros</h4>
      </div>
      <SubscriptionModal />
      <section className={flex}>
        <div>
          <h3>€147</h3>
          <p>IVA incl.</p>
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <p>
            + Plan de suscripción{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={openSubscriptionModal}
              className={interrogant}
            >
              ?
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionInfo;
